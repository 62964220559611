import LoadableComponent from './../Loadable/index';

export const userRouter: any = [
  {
    path: '/user',
    name: 'user',
    title: 'User',
    component: LoadableComponent(() => import('../../components/Layout/UserLayout')),
    isLayout: true,
    showInMenu: false,
    isVisible: true,
  },
  {
    path: '/user/login',
    name: 'login',
    title: 'LogIn',
    component: LoadableComponent(() => import('../../scenes/Login')),
    showInMenu: false,
    isVisible: true,
  },
  {
    path: '/user/register',
    name: 'register',
    title: 'Register',
    component: LoadableComponent(() => import('../../scenes/Register')),
    showInMenu: false,
    isVisible: true,
  },
  {
    path: '/user/forgotPassword',
    name: 'forgotPassword',
    title: 'ForgotPassword',
    component: LoadableComponent(() => import('../../scenes/ForgotPassword')),
    showInMenu: false,
    isVisible: true,
  },
  {
    path: '/user/activateEmail',
    name: 'activateEmail',
    title: 'ActivateEmail',
    component: LoadableComponent(() => import('../../scenes/ActivateEmail')),
    showInMenu: false,
    isVisible: true,
  },
  {
    path: '/user/resetPassword',
    name: 'resetPassword',
    title: 'ResetPassword',
    component: LoadableComponent(() => import('../../scenes/ResetPassword')),
    showInMenu: false,
    isVisible: true,
  },
];

export const appRouters: any = [
  {
    path: '/',
    exact: true,
    name: 'home',
    permission: '',
    title: 'Home',
    icon: 'home',
    component: LoadableComponent(() => import('../../components/Layout/AppLayout')),
    isLayout: true,
    showInMenu: false,
    isVisible: true,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    permission: '',
    title: 'Dashboard',
    icon: 'home',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Dashboard')),
    isVisible: true,
  },
  {
    path: '/users',
    permission: 'Pages.Users',
    title: 'Users',
    name: 'user',
    icon: 'user',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Users')),
    isVisible: true,
  },
  {
    path: '/feedbacks',
    permission: 'Pages.Feedbacks',
    title: 'Feedbacks',
    name: 'feedback',
    icon: 'mail',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Feedbacks')),
    isVisible: true,
  },
  {
    path: '/roles',
    permission: 'Pages.Roles',
    title: 'Roles',
    name: 'role',
    icon: 'tags',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Roles')),
    isVisible: true,
  },
  {
    path: '/tenants',
    permission: 'Pages.Tenants',
    title: 'Tenants',
    name: 'tenant',
    icon: 'appstore',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Tenants')),
    isVisible: true,
  },
  {
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'logout',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../components/Logout')),
    isVisible: true,
  },
  {
    path: '/exception',
    permission: '',
    title: 'exception',
    name: 'exception',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Exception')),
    isVisible: true,
  },
];

export const canvasRouters: any = [
  {
    path: '/',
    exact: true,
    name: 'home',
    permission: 'Pages.Canvas',
    title: 'Home',
    icon: 'home',
    component: LoadableComponent(() => import('../../components/Layout/CanvasLayout')),
    isLayout: true,
    showInMenu: false,
    isVisible: true,
  },
  {
    path: '/canvas',
    name: 'canvas',
    permission: 'Pages.Canvas',
    title: 'Canvas',
    icon: 'picture',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Canvas')),
    isVisible: true,
  },
  {
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'logout',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../components/Logout')),
    isVisible: true,
  },
  {
    path: '/exception',
    permission: '',
    title: 'exception',
    name: 'exception',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Exception')),
    isVisible: true,
  },
];
