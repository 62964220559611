import { action, observable } from 'mobx';
import { ProfileDto } from '../services/profile/dto/profileDto';
import profileService from '../services/profile/profileService';

class ProfileStore {
  @observable profile!: ProfileDto;

  @action
  async getProfile() {
    let result = await profileService.getProfile();
    this.profile = result;
  }

  @action
  async updateProfile(input: ProfileDto) {
    await profileService.updateProfile(input);
  }
}

export default ProfileStore;
