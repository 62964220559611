import http from '../httpService';
import { FeedbackDto } from './dto/FeedbackDto';
import { PagedFeedbackResultRequestDto } from './dto/PagedFeedbackResultRequestDto';
import { PagedResultDto } from '../dto/pagedResultDto';

class FeedbackService {
  public async getAll(pagedFilterAndSortedRequest: PagedFeedbackResultRequestDto): Promise<PagedResultDto<FeedbackDto>> {
    let result = await http.get('api/services/app/Feedback/GetAll', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }

  public async createFeedback(input: FeedbackDto) {
    await http.post('api/services/app/Feedback/CreateFeedback', input);
  }
}

export default new FeedbackService();
