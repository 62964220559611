import http from '../httpService';
import { ProfileDto } from './dto/profileDto';

class ProfileService {
  public async getProfile(): Promise<ProfileDto> {
    let result = await http.get('api/services/app/Profile/GetProfile');
    return result.data.result;
  }

  public async updateProfile(input: ProfileDto) {
    await http.put('api/services/app/Profile/UpdateProfile', input);
  }
}

export default new ProfileService();
