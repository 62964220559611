import * as React from 'react';

import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import utils from '../../utils/utils';

const Router = () => {
  const UserLayout = utils.getRoute('/user').component;
  let layout;
  if (abp.auth.isGranted('Pages.Canvas')) {
    const CanvasLayout = utils.getRoute('/').component;
    layout = <ProtectedRoute path="/" render={(props: any) => <CanvasLayout {...props} exact />} />;
  } else {
    const AppLayout = utils.getRoute('/').component;
    layout = <ProtectedRoute path="/" render={(props: any) => <AppLayout {...props} exact />} />;
  }

  return (
    <Switch>
      <Route path="/user" render={(props: any) => <UserLayout {...props} />} />
      {layout}
    </Switch>
  );
};

export default Router;
