import { GetCurrentLoginInformations } from './dto/getCurrentLoginInformations';
import http from '../httpService';
import { ChangeLanguagaInput } from './dto/changeLanguageInput';

declare var abp: any;

class SessionService {
  public async getCurrentLoginInformations(): Promise<GetCurrentLoginInformations> {
    let result = await http.get('api/services/app/Session/GetCurrentLoginInformations', {
      headers: {
        'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
      },
    });

    return result.data.result;
  }

  public async changeLanguage(changeLanguageInput: ChangeLanguagaInput) {
    let result = await http.post('api/services/app/Session/ChangeLanguage', changeLanguageInput);
    return result.data;
  }
}

export default new SessionService();
