import { action, observable } from 'mobx';

import IsTenantAvaibleOutput from '../services/account/dto/isTenantAvailableOutput';
import accountService from '../services/account/accountService';
import { RegisterInput } from '../services/account/dto/registerInput';
import { RegisterOutput } from '../services/account/dto/registerOutput';
import ActivateEmailInput from '../services/account/dto/activateEmailInput';
import ActivateEmailOutput from '../services/account/dto/activateEmailOutput';
import ResetPasswordInput from '../services/account/dto/resetPasswordInput';

class AccountStore {
  @observable tenant: IsTenantAvaibleOutput = new IsTenantAvaibleOutput();

  @action
  public isTenantAvailable = async (tenancyName: string) => {
    this.tenant = await accountService.isTenantAvailable({ tenancyName: tenancyName });
  };

  @action
  public register = async (registerInput: RegisterInput): Promise<RegisterOutput> => {
    return await accountService.register(registerInput);
  };

  @action
  public activateEmail = async (activateEmailInput: ActivateEmailInput): Promise<ActivateEmailOutput> => {
    return await accountService.activateEmail(activateEmailInput);
  };

  @action
  public forgotPassword = async (emailAddress: string) => {
    return await accountService.forgotPassword(emailAddress);
  };

  @action
  public resetPassword = async (resetPasswordInput: ResetPasswordInput) => {
    return await accountService.resetPassword(resetPasswordInput);
  };
}

export default AccountStore;
