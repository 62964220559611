import { CreateOrUpdateUserInput } from './dto/createOrUpdateUserInput';
import { EntityDto } from '../../services/dto/entityDto';
import { GetAllUserOutput } from './dto/getAllUserOutput';
import { PagedResultDto } from '../../services/dto/pagedResultDto';
import { PagedUserResultRequestDto } from './dto/PagedUserResultRequestDto';
import { UpdateUserInput } from './dto/updateUserInput';
import http from '../httpService';
import AppConsts from '../../lib/appconst';
import { saveAs } from 'file-saver';
import { ChangePasswordDto } from './dto/changePasswordDto';

class UserService {
  public async create(createUserInput: CreateOrUpdateUserInput) {
    let result = await http.post('api/services/app/User/Create', createUserInput);
    return result.data.result;
  }

  public async update(updateUserInput: UpdateUserInput) {
    let result = await http.put('api/services/app/User/Update', updateUserInput);
    return result.data.result;
  }

  public async delete(entityDto: EntityDto) {
    let result = await http.delete('api/services/app/User/Delete', { params: entityDto });
    return result.data;
  }

  public async getRoles() {
    let result = await http.get('api/services/app/User/GetRoles');
    return result.data.result.items;
  }

  public async get(entityDto: EntityDto): Promise<CreateOrUpdateUserInput> {
    let result = await http.get('api/services/app/User/Get', { params: entityDto });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedUserResultRequestDto): Promise<PagedResultDto<GetAllUserOutput>> {
    let result = await http.get('api/services/app/User/GetAll', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }

  public async changePassword(input: ChangePasswordDto) {
    let result = await http.post('api/services/app/Account/ChangeUserPassword', input);
    return result.data.result;
  }

  public async getUsersToExcel(pagedFilterAndSortedRequest: PagedUserResultRequestDto) {
    let result = await http.get('api/services/app/User/GetUsersToExcel', { params: pagedFilterAndSortedRequest });
    let file = result.data.result;
    const _url =
      AppConsts.remoteServiceBaseUrl +
      '/File/DownloadTempFile?fileType=' +
      file.fileType +
      '&fileToken=' +
      file.fileToken +
      '&fileName=' +
      file.fileName;
    saveAs(_url, file.fileName);
    // const url = window.URL.createObjectURL(new Blob([result.data.result], { type: 'application/vnd.ms-excel' }));
    //  const url = window.URL.createObjectURL(result.data);

    // const link = document.createElement('a');

    // link.href = _url;
    // link.setAttribute('download', 'Detail_Blast.xls');
    // document.body.appendChild(link);
    // link.click();
    // alert("File Berhasil Didownload!")

    // const blob = new Blob([result.data], {
    //   type:
    //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    // });
    // let url = window.URL.createObjectURL(blob);
    //saveAs(_url, 'usesas.xlsx');
    // saveAs(blob, 'users.xlsx');

    // return result.data.blob();
    //return result.data.result;
  }
}

export default new UserService();
