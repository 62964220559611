import http from '../httpService';
import { EntityDto } from '../../services/dto/entityDto';
import { CanvasModelFolderDto } from './dto/CanvasModelFolderDto';
import { CanvasModelDto } from './dto/CanvasModelDto';
import { UpdateTreePathDto } from './dto/UpdateTreePathDto';
import { CanvasModelTreeDto } from './dto/CanvasModelTreeDto';
import { CanvasModelTypeDto } from './dto/CanvasModelTypeDto';
import { CanvasModelReference } from './dto/CanvasModelReference';
import { CanvasModelRaciRole } from './dto/CanvasModelRaciRole';
import { SetCanvasModelFolderDto } from './dto/SetCanvasModelFolderDto';
import { CopyCanvasDto } from './dto/CopyCanvasDto';

class CanvasService {
  public async getCanvasModelsAsTree(): Promise<CanvasModelTreeDto[]> {
    let result = await http.get('api/services/app/Canvas/GetCanvasModelsAsTree');
    return result.data.result;
  }

  public async getReferences(entityDto: EntityDto): Promise<CanvasModelReference[]> {
    let result = await http.get('api/services/app/Canvas/GetReferences', { params: entityDto });
    return result.data.result;
  }

  public async getRaciRoles(): Promise<CanvasModelRaciRole[]> {
    let result = await http.get('api/services/app/Canvas/GetRaciRoles');
    return result.data.result;
  }

  public async updateTreePath(input: UpdateTreePathDto[]) {
    let result = await http.put('api/services/app/Canvas/UpdateTreePath', { items: input });
    return result.data.result;
  }

  public async getCanvasModelTypes(): Promise<CanvasModelTypeDto[]> {
    let result = await http.get('api/services/app/Canvas/GetCanvasModelTypes');
    return result.data.result;
  }

  public async getCanvasModelFolderById(entityDto: EntityDto): Promise<CanvasModelFolderDto> {
    let result = await http.get('api/services/app/Canvas/GetCanvasModelFolderById', { params: entityDto });
    return result.data.result;
  }

  public async createOrEditCanvasModelFolder(input: CanvasModelFolderDto): Promise<number> {
    let result = await http.post('api/services/app/Canvas/CreateOrEditCanvasModelFolder', input);
    return result.data.result;
  }

  public async deleteCanvasModelFolder(entityDto: EntityDto) {
    let result = await http.delete('api/services/app/Canvas/DeleteCanvasModelFolder', { params: entityDto });
    return result.data.result;
  }

  public async getCanvasModels(): Promise<CanvasModelDto[]> {
    let result = await http.get('api/services/app/Canvas/GetCanvasModels');
    return result.data.result;
  }

  public async getCanvasModelById(entityDto: EntityDto): Promise<CanvasModelDto> {
    let result = await http.get('api/services/app/Canvas/GetCanvasModelById', { params: entityDto });
    return result.data.result;
  }

  public async createOrEditCanvasModel(input: CanvasModelDto, saveHistory: boolean = false): Promise<number> {
    if (saveHistory) {
      let result = await http.post('api/services/app/Canvas/CreateOrEditCanvasModel', { saveHistory: saveHistory, ...input });
      return result.data.result;
    } else {
      let result = await http.post('api/services/app/Canvas/CreateOrEditCanvasModel', input);
      return result.data.result;
    }
  }

  public async setCanvasModelFolder(input: SetCanvasModelFolderDto) {
    await http.post('api/services/app/Canvas/SetCanvasModelFolder', input);
  }

  public async insertModelByAnotherCanvasModel(input: CopyCanvasDto) {
    await http.post('api/services/app/Canvas/InsertModelByAnotherCanvasModel', input);
  }

  public async hasRefenceForCanvasModel(entityDto: EntityDto) {
    let result = await http.post('api/services/app/Canvas/HasRefenceForCanvasModel', entityDto);
    return result.data.result;
  }

  public async deleteCanvasModel(entityDto: EntityDto) {
    let result = await http.delete('api/services/app/Canvas/DeleteCanvasModel', { params: entityDto });
    return result.data.result;
  }

  public async getModelAsPdf(id: number, verticalCount: number, horizontalCount: number, img: any, print: boolean) {
    let result = await http.post(
      'File/CreatePdfFromImage?Id=' + id + '&VerticalCount=' + verticalCount + '&HorizontalCount=' + horizontalCount,
      { img },
      {
        responseType: 'blob',
      }
    );
    return result;
  }

  public async getModelAsPdfWithDetail(input: any) {
    let result = await http.post(
      'File/CreatePdfWithDetail',
      input,
      {
        responseType: 'blob',
      }
    );
    return result;
  }


  public async getLoadPruexxUserGuidePdf() {
    let result = await http.post(
      'File/LoadPruexxUserGuidePdf',
      null,
      {
        responseType: 'blob',
      }
    );
    return result;
  }
}

export default new CanvasService();
