import { IsTenantAvaibleInput } from './dto/isTenantAvailableInput';
import { RegisterInput } from './dto/registerInput';
import IsTenantAvaibleOutput from './dto/isTenantAvailableOutput';
import { RegisterOutput } from './dto/registerOutput';
import http from '../httpService';
import ActivateEmailInput from './dto/activateEmailInput';
import ActivateEmailOutput from './dto/activateEmailOutput';
import ResetPasswordInput from './dto/resetPasswordInput';

class AccountService {
  public async isTenantAvailable(isTenantAvaibleInput: IsTenantAvaibleInput): Promise<IsTenantAvaibleOutput> {
    let result = await http.post('api/services/app/Account/IsTenantAvailable', isTenantAvaibleInput);
    return result.data.result;
  }

  public async register(registerInput: RegisterInput): Promise<RegisterOutput> {
    let result = await http.post('api/services/app/Account/Register', registerInput);
    return result.data.result;
  }

  public async activateEmail(activateEmailInput: ActivateEmailInput): Promise<ActivateEmailOutput> {
    let result = await http.post('api/services/app/Account/ActivateEmail', activateEmailInput);
    return result.data.result;
  }

  public async forgotPassword(emailAddress: string) {
    let result = await http.post('api/services/app/Account/ForgotPassword', { emailAddress: emailAddress });
    return result.data.result;
  }

  public async resetPassword(resetPasswordInput: ResetPasswordInput) {
    let result = await http.post('api/services/app/Account/ResetPassword', resetPasswordInput);
    return result.data.result;
  }
}

export default new AccountService();
