import { action, observable } from 'mobx';
import feedbackService from '../services/feedback/feedbackService';
import { FeedbackDto } from '../services/feedback/dto/FeedbackDto';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { PagedFeedbackResultRequestDto } from '../services/feedback/dto/PagedFeedbackResultRequestDto';

class FeedbackStore {
  //@observable createOrEditCanvasModelFolderDto!: CanvasModelFolderDto;
  @observable feedbacks!: PagedResultDto<FeedbackDto>;

  @action
  async getAll(pagedFilterAndSortedRequest: PagedFeedbackResultRequestDto) {
    let result = await feedbackService.getAll(pagedFilterAndSortedRequest);
    this.feedbacks = result;
  }

  @action
  async createFeedback(input: FeedbackDto) {
    await feedbackService.createFeedback(input);
  }
}

export default FeedbackStore;
