import { action, observable } from 'mobx';
import $ from 'jquery';
import canvasService from '../services/canvas/canvasService';
import { CanvasModelTreeDto } from '../services/canvas/dto/CanvasModelTreeDto';
import { CanvasModelFolderDto } from '../services/canvas/dto/CanvasModelFolderDto';
import { CanvasModelDto } from '../services/canvas/dto/CanvasModelDto';
import { CanvasModelTypeDto } from '../services/canvas/dto/CanvasModelTypeDto';
import { UpdateTreePathDto } from '../services/canvas/dto/UpdateTreePathDto';

// import { EntityDto } from '../services/dto/entityDto';

class CanvasStore {
  @observable createOrEditCanvasModelFolderDto!: CanvasModelFolderDto;
  @observable createOrEditCanvasModelDto!: CanvasModelDto;
  @observable canvasModelsAsTree: CanvasModelTreeDto[] = [];
  @observable modelTypes: CanvasModelTypeDto[] = [];
  @observable models: CanvasModelDto[] = [];

  @observable selectedModels: CanvasModelDto[] = [];

  @observable currentModel?: CanvasModelDto;

  @action
  async getCanvasModelsAsTree() {
    let result = await canvasService.getCanvasModelsAsTree();
    this.canvasModelsAsTree = result;
  }

  @action
  async getReferences(input?: number) {
    let n = input ? input : 0;
    let result = await canvasService.getReferences({ id: n });
    return result;
  }

  @action
  async getRaciRoles() {
    let result = await canvasService.getRaciRoles();
    ($(document) as any).setRaciModels(result);
    return result;
  }

  @action
  async updateTreePath(input: UpdateTreePathDto[]) {
    await canvasService.updateTreePath(input);
    await this.getCanvasModelsAsTree();
  }

  @action
  async getCanvasModelTypes() {
    let result = await canvasService.getCanvasModelTypes();
    this.modelTypes = result;
  }

  @action
  async getCanvasModelFolderById(input?: number) {
    if (input) {
      let result = await canvasService.getCanvasModelFolderById({ id: input });
      this.createOrEditCanvasModelFolderDto = result;
    }
  }

  @action
  async createOrEditCanvasModelFolder(input: CanvasModelFolderDto) {
    let result = await canvasService.createOrEditCanvasModelFolder(input);
    await this.getCanvasModelsAsTree();
    return result;
  }

  @action
  async deleteCanvasModelFolder(input: number) {
    await canvasService.deleteCanvasModelFolder({ id: input });
    await this.getCanvasModelsAsTree();
  }

  @action
  async getCanvasModels() {
    let result = await canvasService.getCanvasModels();
    this.models = result;
  }

  @action
  async getCanvasModelById(input?: number) {
    if (input) {
      let result = await canvasService.getCanvasModelById({ id: input });
      this.createOrEditCanvasModelDto = result;
    }
  }

  @action
  async createOrEditCanvasModel(input: CanvasModelDto, saveHistory: boolean = false) {
    let result = await canvasService.createOrEditCanvasModel(input, saveHistory);
    await this.getCanvasModelsAsTree();
    await this.getCanvasModels();
    return result;
  }

  @action
  async hasRefenceForCanvasModel(input: number) {
    let result = await canvasService.hasRefenceForCanvasModel({ id: input });
    return result;
  }

  @action
  async setCanvasModelFolder(canvasModelId: number, canvasFolderId?: number) {
    await canvasService.setCanvasModelFolder({ canvasModelId, canvasFolderId });
    await this.getCanvasModelsAsTree();
    await this.getCanvasModels();
    await this.getRaciRoles();
  }

  @action
  async insertModelByAnotherCanvasModel(canvasModelId: number, canvasFolderId?: number) {
    await canvasService.insertModelByAnotherCanvasModel({ canvasModelId, canvasFolderId });
    await this.getCanvasModelsAsTree();
    await this.getCanvasModels();
    await this.getRaciRoles();
  }

  @action
  async deleteCanvasModel(input: number) {
    await canvasService.deleteCanvasModel({ id: input });
    await this.getCanvasModelsAsTree();
    await this.getCanvasModels();
    await this.getRaciRoles();
  }

  @action
  setCurrentModel(input?: CanvasModelDto) {
    this.currentModel = input;
  }

  @action
  async getModelAsPdf(id: number, verticalCount: number, horizontalCount: number, img: any, print: boolean) {
    let result = await canvasService.getModelAsPdf(id, verticalCount, horizontalCount, img, print);

    let blob = result.data;

    if (print) {
      let pdfFrame = document.createElement('frame');
      pdfFrame.id = 'pdf-frame';
      var objectURL = URL.createObjectURL(blob);
      pdfFrame.src = objectURL;
      document.body.appendChild(pdfFrame);

      window.setTimeout(function () {
        pdfFrame.focus();
        pdfFrame.contentWindow!.print();
      }, 1000);
    } else {
      var pdfURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.href = pdfURL;
      let name = this.models.filter((n) => n.id === id)[0].name;
      tempLink.setAttribute('download', name + '.pdf');
      tempLink.click();
    }
  }

  @action
  async getModelAsPdfWithDetail(input: any, print: boolean) {
    let result = await canvasService.getModelAsPdfWithDetail(input);

    let blob = result.data;

    if (print) {
      let pdfFrame = document.createElement('frame');
      pdfFrame.id = 'pdf-frame';
      var objectURL = URL.createObjectURL(blob);
      pdfFrame.src = objectURL;
      document.body.appendChild(pdfFrame);

      window.setTimeout(function () {
        pdfFrame.focus();
        pdfFrame.contentWindow!.print();
      }, 1000);
    } else {
      var pdfURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.href = pdfURL;
      let name = this.models.filter((n) => n.id === input[0].id)[0].name;
      tempLink.setAttribute('download', name + '.pdf');
      tempLink.click();
    }
  }

  @action
  async getLoadPruexxUserGuidePdf() {
    let result = await canvasService.getLoadPruexxUserGuidePdf();

    let blob = result.data;

    var pdfURL = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = pdfURL;
    let name = "Pruexx_User_Guide.pdf";
    tempLink.setAttribute('download', name);
    tempLink.click();
  }

  // @action
  // async update(updateUserInput: UpdateUserInput) {
  //    let result = await userService.update(updateUserInput);
  //    this.users.items = this.users.items.map((x: GetUserOutput) => {
  //       if (x.id === updateUserInput.id) x = result;
  //       return x;
  //    });
  // }

  // @action
  // async delete(entityDto: EntityDto) {
  //    await userService.delete(entityDto);
  //    this.users.items = this.users.items.filter((x: GetUserOutput) => x.id !== entityDto.id);
  // }

  // @action
  // async getRoles() {
  //    let result = await userService.getRoles();
  //    this.roles = result;
  // }

  // @action
  // async get(entityDto: EntityDto) {
  //    let result = await userService.get(entityDto);
  //    this.editUser = result;
  // }

  // @action
  // async createUser() {
  //    this.editUser = {
  //       userName: '',
  //       name: '',
  //       surname: '',
  //       emailAddress: '',
  //       isActive: false,
  //       roleNames: [],
  //       password: '',
  //       id: 0,
  //    };
  //    this.roles = [];
  // }

  // @action
  // async getAll(pagedFilterAndSortedRequest: PagedUserResultRequestDto) {
  //    let result = await userService.getAll(pagedFilterAndSortedRequest);
  //    this.users = result;
  // }
}

export default CanvasStore;
